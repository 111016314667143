body {
    margin: 0;
}

.title-container {
    text-align: center;
}
.grades-container {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.grade-card {
    width: 250px;
    text-align: center;
}
.grade-card-icon {
    font-size: 36px;
    color: #1677ff;
    margin: 0;
}
.youtube-container {
    margin-top: 20px;
    text-align: center;
}

iframe {
    width: 100%;
    max-width: 800px; /* Adjust the maximum width as needed */
    min-height: 450px;
    height: 100%;
    border: 0;
}

@media screen and (max-width: 768px) {
    .grades-container {
        flex-direction: column; /* Display vertically for small screens */
        align-items: center; /* Center items horizontally */
    }

    .grade-card {
        margin-bottom: 10px; /* Add some space between cards */
    }

    .youtube-container {
        width: 100%; /* Take full width on small screens */
    }
    iframe {
        min-height: 150px;
    }
}